/*eslint-disable*/
// export const virtualCustomerInfo =
// {
//     title: '客户信息',
//     ref: 'customerForm',
//     formName: 'customerInfo',
//     col: 4,
//     labelWidth: '150px',
//     formList: [
//         {
//             prop: 'customerCode',
//             label: '客户编码',
//             placeholder: ' ',
//             // type: 'input',
//             type: 'custom',
//             visible: true,
//             disabled: true
//         },
//         // {
//         //     label: '关联主客户',
//         //     prop: 'customerName2',
//         //     // type: 'select',
//         //     type: 'custom',
//         //     visible: true,
//         //     disabled: true,
//         // },
//         // {
//         //     label: '主客户名称',
//         //     prop: 'customerName3',
//         //     // type: 'select',
//         //     type: 'custom',
//         //     placeholder: ' ',
//         //     visible: true,
//         //     disabled: true,
//         // },
//         {
//             prop: 'customerBodyType',
//             label: '客户类型',
//             // type: 'input',
//             type: 'custom',
//             visible: true,
//             optionsKey: 'SYS_RELATION_LEVEL',
//         },
//         {
//             label: '是否虚拟客户',
//             prop: 'isVirtualCustomer',
//             // type: 'advance',
//             type: 'custom',
//             optionsKey: 'SYS_CRM_YES_NO',
//             visible: true,
//             disabled: true
//         },
//         {
//             label: '客户状态',
//             prop: 'customerStatus',
//             // type: 'advance',
//             type: 'custom',
//             optionsKey: 'SYS_WM_CUSTOMER_STATUS',
//             visible: true,
//             disabled: true
//         }
//     ]
// }

// 表单配置信息
export const collapse = [
    {
        title: '客户信息',
        ref: 'customerForm',
        formName: 'customerInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            {
                prop: 'customerCode',
                label: '客户编码',
                placeholder: ' ',
                // type: 'input',
                type: 'custom',
                visible: true,
                disabled: true
            },
            {
                label: '集团编码',
                prop: 'originalGroupCode',
                type: 'custom',
                visible: true,
                disabled: true,
            },
            {
                label: '客户简称',
                prop: 'customerShortName',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: true,
            },
            {
                label: '客户类型',
                prop: 'customerBodyType',
                // type: 'select',
                type: 'custom',
                placeholder: ' ',
                visible: true,
                disabled: true,
                optionsKey: 'SYS_RELATION_LEVEL'
            },
            // {
            //     label: '客户状态',
            //     prop: 'customerStatus',
            //     // type: 'advance',
            //     type: 'custom',
            //     optionsKey: 'SYS_WM_CUSTOMER_STATUS',
            //     visible: true,
            //     disabled: true
            // },
            // {
            //     label: '内外渠',
            //     prop: 'customerChannel',
            //     // type: 'advance',
            //     type: 'custom',
            //     optionsKey: 'LMDM_CUSTOMER_CHANNEL',
            //     visible: true,
            //     disabled: true
            // },
            // {
            //     label: '渠道角色',
            //     prop: 'customerChannelRole',
            //     // type: 'advance',
            //     type: 'custom',
            //     optionsKey: 'LMDM_CUSTOMER_CHANNEL_ROLE',
            //     visible: true,
            //     disabled: true
            // }
            // {
            //     label: '供应商编码',
            //     prop: 'xx2',
            //     // type: 'advance',
            //     type: 'custom',
            //     visible: true,
            //     disabled: true
            // }
        ]
    },

    {
        title: '管理信息',
        ref: 'manageForm',
        formName: 'manageInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            // {
            //     prop: 'customerShortName',
            //     label: '客户简称',
            //     placeholder: ' ',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true,
            //     disabled: true
            // },
            // {
            //     prop: 'customerClassify',
            //     label: '客户分类',
            //     placeholder: ' ',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true,
            //     disabled: true,
            //     optionsKey: 'PMS_CUSTOMER_CLASSIFY',
            // },
            {
                label: '客户系',
                prop: 'customerGroupCode',
                // type: 'select',
                type: 'custom',
                optionsKey: 'SYS_CUST_RELATION_TYPE',
                visible: true,
                disabled: true,
            },
            {
                label: '行业类型',
                prop: 'industryType',
                // type: 'select',
                type: 'custom',
                value: '',
                placeholder: ' ',
                visible: true,
                disabled: true,
                optionsKey: 'SYS_INDUSTRY_TYPE'
            },
            // {
            //     prop: 'industryCategories',
            //     label: '行业大类',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true,
            // },
            {
                label: '客户等级',
                prop: 'customerLevelCode',
                // type: 'advance',
                type: 'custom',
                optionsKey: 'SYS_WM_CUSTOMER_LEVEL',
                // options: [
                //     { value: 2, label: '普通' },
                //     { value: 4, label: 'TOP' }
                // ],
                visible: true,
                disabled: true
            },
            {
                label: '内外渠',
                prop: 'customerChannel',
                // type: 'advance',
                type: 'custom',
                optionsKey: 'LMDM_CUSTOMER_CHANNEL',
                visible: true,
                disabled: true
            },
            {
                label: '渠道角色',
                prop: 'customerChannelRole',
                // type: 'advance',
                type: 'custom',
                optionsKey: 'LMDM_CUSTOMER_CHANNEL_ROLE',
                visible: true,
                disabled: true
            },
            {
                label: '供应商编码',
                prop: 'supplierCode',
                // type: 'advance',
                type: 'custom',
                visible: true,
                disabled: true
            },
            {
                label: '是否虚拟客户',
                prop: 'isVirtualCustomer',
                // type: 'advance',
                type: 'custom',
                optionsKey: 'SYS_CRM_YES_NO',
                visible: true,
                disabled: true
            },
            {
                label: '所属事业部',
                prop: 'ebcuBuCode',
                // type: 'advance',
                type: 'custom',
                optionsKey: 'SYS_BUSINESS_UNIT',
                visible: true,
                disabled: true
            },
            {
                label: '内部OU组织',
                prop: 'ouOrganization',
                // type: 'advance',
                type: 'custom',
                visible: true,
                disabled: true
            },
            {
                label: '风险等级',
                prop: 'riskLevel',
                // type: 'advance',
                optionsKey: 'LMDM_RISKLEVEL',
                type: 'custom',
                visible: true,
                disabled: true
            },
        ]
    },
    {
        title: '信用信息',
        ref: 'basicForm',
        formName: 'basicInfo',
        col: 4,
        labelWidth: '150px',
        formList: [
            {
                label: '社会信用代码',
                prop: 'societyCreditCode',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: false
            },
            // {
            //     label: '法人代表',
            //     prop: 'linkageType',
            //     // type: 'select',
            //     type: 'custom',
            //     visible: true
            // },
            // {
            //     label: '身份证号码',
            //     prop: 'idNumber',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true
            // },
            // {
            //     label: '企业性质',
            //     prop: 'freightFloatMode',
            //     // type: 'select',
            //     type: 'custom',
            //     visible: true
            // },
            // {
            //     label: '纳税人类型',
            //     prop: 'freightLinkedScale',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true,
            //     optionsKey: 'SRM_YES_NO',
            // },
            // {
            //     label: '国家',
            //     prop: 'freightFloatCoefficient',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true
            // },
            {
                label: '省份',
                prop: 'ebcuEbplProvinceCode',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: false
            },
            {
                label: '市区',
                prop: 'ebcuEbplCityCode',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: false
            },
            {
                label: '区县',
                prop: 'ebcuDistricts',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: false
            },
            {
                label: '详情地址',
                prop: 'ebcuAddress',
                // type: 'select',
                type: 'custom',
                visible: true,
                disabled: false,
                span: 24,
            },
            // {
            //     label: '注册地址/身份证地址',
            //     prop: 'conditionsDetail',
            //     // type: 'input',
            //     type: 'custom',
            //     span: 24,
            //     visible: true
            // },
            // {
            //     label: '销售产品',
            //     prop: 'conditionsDetail',
            //     // type: 'input',
            //     type: 'custom',
            //     span: 24,
            //     visible: true
            // },
            // {
            //     label: '所属事业部',
            //     prop: 'conditionsDetail',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true,
            //     optionsKey: 'SRM_YES_NO',
            // },
            // {
            //     label: '是否关联交易',
            //     prop: 'conditionsDetail',
            //     // type: 'input',
            //     type: 'custom',
            //     optionsKey: 'SRM_YES_NO',
            //     visible: true
            // },
            // {
            //     label: '内部OU组织',
            //     prop: 'conditionsDetail',
            //     // type: 'input',
            //     type: 'custom',
            //     visible: true,
            //     optionsKey: 'SRM_YES_NO',
            // },
            // {
            //     label: '是否POS机收款',
            //     prop: 'conditionsDetail',
            //     // type: 'input',
            //     type: 'custom',
            //     optionsKey: 'SRM_YES_NO',
            //     visible: true
            // }
        ]
    },
];
