export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        // {
        //     label: '合同编码',
        //     prop: 'contractCode'
        // },
        {
            label: '客户编码',
            prop: 'customerCode',
            sort: true,
            width: 114
        },
        {
            label: '盖章人姓名',
            prop: 'stamperName',
            sort: true,
            width: 114
        },
        {
            label: '盖章人身份证',
            prop: 'stamperIdNumber',
            sort: true,
            width: 130
        },
        {
            label: '盖章人手机',
            prop: 'stamperMobile',
            sort: true,
            width: 114
        },
        {
            label: '盖章人状态',
            prop: 'stampStatus',
            type: 'select',
            optionsKey: 'SYS_LMDM_SEAL_STATE',
            sort: true,
            width: 114
        },
        {
            label: '盖章人邮箱',
            prop: 'stamperEmail',
            sort: true,
            width: 114
        },
        {
            label: '状态',
            prop: 'enableFlag',
            type: 'select',
            optionsKey: 'SYS_BMS_ENABLE_FLAG',
            sort: true,
            width: 114
        },
        {
            label: '创建人',
            prop: 'createUserCode'
        },
        {
            label: '创建时间',
            prop: 'createTime',
            sort: true,
            width: 154
        },
        {
            label: '修改人',
            prop: 'updateUserCode'
        },
        {
            label: '修改时间',
            prop: 'updateTime',
            sort: true,
            width: 154
        }
    ]
};
