<template>
    <div class="costomer-manage">
        <Paper class="costomer-manage-paper">
            <el-tabs
                class="costomer-list-tab-pane"
                v-model="activeTabs"
                @tab-remove="removeTab"
                @tab-click="switchTabs">
                <!-- 合同列表页 -->
                <el-tab-pane
                    label="客户盖章人/业务实体维护"
                    name="theList"
                    ref="myPane"
                    v-loading="holePageLoading">
                    <list-layout-paper>
                        <template slot="header-search" class="searchBox">
                            <search-box
                                ref="searchBox"
                                :search-key="config.name"
                                :fields="config.searchFields"
                                :showResetBtn="true"
                                @search-change="contractList.searchList"
                            />
                        </template>
                        <template slot="header-button">
                            <lotsButton
                                type="primary"
                                size="mini"
                                title="刷新"
                                @click="refresh"
                                plain>
                                <span class="icon iconfont icon-refresh"></span>
                            </lotsButton>
                            <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                                <el-button
                                icon="iconfont icon-list_install"
                                type="primary"
                                size="mini"
                                :title="'列配置'"
                                @click="showColumnConfigDialog"
                                plain />
                            </el-tooltip>
                        </template>
                        <!-- 表格 -->
                        <template v-slot:list="{ tableHeight }">
                            <table-box
                                ref="clientOrder"
                                :height="tableHeight"
                                v-loading="contractList.tableLoading"
                                :selection="config.selection"
                                :index="config.index"
                                :columns="config.tableData.columns"
                                @row-dblclick="rowDBclick"
                                :rows="contractList.totalData">
                                <template v-slot:col-append="{ col, row }">
                                    <div v-if="col.prop === 'businessType'">{{col.prop === 'businessType' ? row.busiLabelList:row.businessType}}</div>
                                </template>
                            </table-box>
                        </template>
                        <template slot="footer">
                            <lots-pagination
                                @size-change="contractList.sizeChange"
                                :current-page.sync="contractList.pageNo"
                                @current-change="contractList.pageChange"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="contractList.pageTotal" >
                            </lots-pagination>
                        </template>
                    </list-layout-paper>
                </el-tab-pane>
                <!-- 详情页签 -->
                <el-tab-pane
                    closable
                    v-for="item in tabList"
                    :key="item.code + item.detailPageState"
                    :label="item.contractMark"
                    :name="item.code + item.detailPageState">
                    <detail-tab
                        :ref="`datailTabs${item.code + item.detailPageState}`"
                        :code="item.code"
                        :detailPageState="item.detailPageState"
                        :detailData="item.datailData"
                        @other-change="otherChange"
                        @refrash-data="refrashData"
                        @subTabDbClick="rowDBclick">
                    </detail-tab>
                </el-tab-pane>
            </el-tabs>
            <!-- 表格列配置弹框 -->
            <column-config
                :pageID="config.name"
                :initColumns="config.tableData.columns"
                ref="otpColumnConfig"
                @header-change="config.tableData.columns = $event"
            />
        </Paper>
    </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import { getCustomerInfoPage } from '@mdm/api/customer/customer.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import columnConfig from '@/components/lots/columnConfig/Index';
import store from '@/store';
import detailTab from './detail/Detail.vue';
import { Message } from 'element-ui';
import _ from 'lodash';
const THREE = '2'; // 查询长度
export default {
    name: 'customer',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        tableBox,
        lotsPagination,
        lotsButton,
        columnConfig,
        detailTab
    },
    props: {},
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const { user } = store.getters;
        const config = reactive(configStatic);
        const activeTabs = ref('theList');
        const tabList = ref([]); // 打开的详情tab页集
        class ContractListClass extends AdvanceListClass {
            beforeGetList (condition) {
                if ((!condition.customerCode && !condition.customerName)) {
                    Message.warning('请输入客户名称或者客户编码查询');
                    return false;
                }
                const errList = [undefined, null]; // 排除输入0的情况
                if (condition.customerCode !== '' && !errList.includes(condition.customerCode) && condition.customerCode?.length <= +THREE) {
                    Message.warning('客户编码需要至少包含3个字符');
                    return false;
                }
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const contractList = new ContractListClass({
            Api: {
                listApi: getCustomerInfoPage // 查询接口
            }
        });
        const removeTab = (targetName) => { // 删除详情标签
            if (activeTabs.value === targetName) {
                activeTabs.value = 'theList';
            }
            tabList.value = tabList.value.filter(
                (tab) => (tab.code + tab.detailPageState) !== targetName
            );
        };
        const refresh = () => {
            contractList.getList();
        };
        const searchDataFormat = (condition) => {
            if (condition.createTime && condition.createTime.length) {
                condition.startTime = utils.formatDateTime(condition.createTime[0]);
                condition.endTime = utils.formatDateTime(condition.createTime[1]);
                Reflect.deleteProperty(condition, 'createTime');
            }
            if (condition.selectList && condition.selectList.length) {
                condition.createUserCodes = condition.selectList.map(item => item.userCode);
            }
            Reflect.deleteProperty(condition, 'selectList');
            Reflect.deleteProperty(condition, 'userName');
            condition.userCode = user.userCode;
            condition.isNew = condition.isNew ? +condition.isNew : null;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return condition;
        };
        const holePageLoading = ref(false);
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        const switchTabs = (data) => {
            const comKey = `datailTabs${data.name}`;
            if (setupContext.refs[comKey] && setupContext.refs[comKey].length) {
                const { activeName } = setupContext.refs[comKey][0];
                setupContext.refs[comKey][0].handleClickTab({ name: activeName }); // 解决切换详情页tab子页签编辑表格错位问题
            }
        };
        const currDetailData = ref({});
        const formatData = (data) => { // 特殊数据格式处理函数
            if (data.businessType && typeof data.businessType === 'string') {
                data.businessType = data.businessType.split(',') || [];
            }
            return data;
        };
        // 双击表格
        const rowDBclick = (row, pageType = '') => {
            // 滚动到顶部
            const element = document.getElementsByClassName('app-main');
            element[0].scrollTo(0, 0);
            currDetailData.value = formatData(row);
            const status = '';
            const name = currDetailData.value.customerName;
            const code = currDetailData.value.customerCode;
            newDetailPage(code, currDetailData.value, name, status);
        };

        /**
     * @description: 开详情页通用部分代码
     * @param {String} code 页面编码
     * @param {Object} data 页面数据对象
     * @param {String} name 页面名称信息
     * @param {String} state 页面状态  合同tab页状态
     *  */
        // eslint-disable-next-line complexity
        const newDetailPage = (code, data, name, state) => {
            data.contractStatus = data['contractStatus'] ? data['contractStatus'] : 0;
            data.contractSource = data.contractSource ? data.contractSource : '1'; // 新增时默认为1：CRM
            data.oilLinkedProportion = data.oilLinkedProportion || ((['4', '5'].includes(String(data.contractCharacter)) || data.contractSource !== '1') ? '0' : '');
            const judge = tabList.value.filter((item) => (item.code + item.detailPageState) === code + state);
            // data.contractAccountOf = data.contractAccountOf || 0;
            if (judge.length === 0 && tabList.value.length === config.PANELMAX) {
                Message.warning('已超出8个详情页，请关闭后重新打开');
            } else {
                const dataGather = {
                    contractMark: name,
                    code: code,
                    datailData: _.cloneDeep(data),
                    detailPageState: state
                };

                if (judge.length === 0) {
                    // 第一次打开
                    tabList.value.push(dataGather);
                } else { // 已经打开的，直接找到该页面
                    const index = tabList.value.findIndex(v => (v.code + v.detailPageState === judge[0].code + judge[0].detailPageState));
                    // 非新增页面，刷新页面数据 (新增页面，不覆盖已填写的数据， )
                    tabList.value[index] = dataGather;
                }
                activeTabs.value = code + state; // tabs切换到对应详情页
                switchTabs({ name: activeTabs.value }); // 解决切换详情页tab子页签编辑表格错位问题
            }
        };
        const refrashData = (data) => {
            rowDBclick(data);
        };
        const otherChange = (contractCode, newPageData, pageType) => { // 新增其他变更界面
            rowDBclick(newPageData, pageType);
        };
        return {
            config,
            activeTabs,
            removeTab,
            holePageLoading,
            contractList,
            searchDataFormat,
            refresh,
            otpColumnConfig,
            showColumnConfigDialog,
            rowDBclick,
            switchTabs,
            tabList,
            refrashData,
            otherChange
        };
    }
};
</script>

<style lang="less">
.costomer-manage {
    width: 100%;
    .costomer-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }
    .costomer-list-tab-pane.el-tabs {
        width: 100%;
        height: 100%;
        & > .el-tabs__content {
            height: calc(100% - 40px - 14px);
            overflow: auto;
            & > .el-tab-pane {
                overflow: auto;
                padding-right: 5px;
                height: 100%;
                .list-main {
                    .table-box {
                        height: 100%;
                    }
                }
            }
        }
    }
    .flex-layout .list-main {
        overflow: auto;
    }
    .flex-layout .table {
        overflow-y: auto;
    }
    .list-header {
        .costomer-manage-list-dropdown.el-dropdown {
            color: #fff;
        }
    }
    .layout-content.costomer-manage-paper {
        padding: 0px 20px 20px;
        .el-table .cell.el-tooltip {
            &>div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .list-layout-wrapper {
        padding: 0px;
    }
    .list-layout .list-header .el-input {
        margin-left: 0;
    }
}
.costomer-manage-list-dropdown-menu {
    background-color: #fff;
}
.column-config_title {
    font-size: 14px;
}
</style>
