<!-- eslint-disable max-lines -->
<template>
    <div class="file-detail-subtab customer-subtab">
        <div class="file-detail-btn" >
            <lots-button
                type="text"
                icon="el-icon-circle-plus-outline"
                size="medium"
                class="bigData-btn-detailPageEbcaTabInsert"
                v-has="authorityCode.subEcbaAdd"
                @click="handleUseStatus"
                >新增</lots-button>
            <lots-button
                type="text"
                icon="el-icon-remove-outline"
                size="medium"
                class="bigData-btn-detailPageEbcaTabDel"
                :loading="saveLoading"
                v-has="authorityCode.subEcbaDel"
                @click="handleDelStatus">删除</lots-button>
            <lots-button
                type="text"
                icon="el-icon-circle-check"
                size="medium"
                class="bigData-btn-detailPageEbcaTabComfirm"
                :loading="saveLoading"
                v-has="authorityCode.subEcbaConfirm"
                @click="handleConfirmStatus">确认</lots-button>
            <lots-button
                 type="text" icon="el-icon-upload2" size="medium" v-has="authorityCode.subEcbaPush"
                @click="handlePush()"  class="bigData-btn-detailPageEbcaTabPush">推送ERP</lots-button>
            <lots-button @click="subTabTable.getList()" class="bigData-btn-detailPageStamperTabLink" type="text" size="medium" icon="el-icon-refresh" >
                刷新
            </lots-button>
            <advanceDialog ref="advanceDialogRef"  @confirm="detaliSearchSure" />
        </div>
        <div class="file-detail-table">
            <edit-table-box
                class="prescreen"
                v-loading="subTabTable.tableLoading"
                ref="editTableBox"
                :selection="config.selection"
                :control="false"
                :columns="newColumns"
                :height="tableHeight"
                :tableData="subTabTable.totalData"
                :actions="config.actions"
                :editDisableRule="() => false"
                :allowRowClick="false"
                @selection-change="handleSelectedRow"
                >
            </edit-table-box>
            <div class="footer">
                <lots-pagination
                @size-change="subTabTable.sizeChange"
                :current-page.sync="subTabTable.pageNo"
                @current-change="subTabTable.pageChange"
                size="small"
                layout="total, sizes, prev, pager, next, jumper"
                :total="subTabTable.pageTotal" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, watch, onMounted, onUnmounted } from '@vue/composition-api';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import lotsButton from '@/components/lots/lotsButton';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import {
    delEbcaStatus,
    pushEbcaStatus,
    getEbcaInfoList,
    saveEbcaInfo,
    confirmEbcaStatus
} from '@mdm/api/customer/customer.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import { Message, MessageBox } from 'element-ui';
import advanceDialog from '@/components/lots/otpAdvanceDialog/index';
import _ from 'lodash';
export default {
    name: '',
    components: {
        lotsPagination,
        lotsButton,
        editTableBox,
        advanceDialog
    },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.customer);
        // const refs = setupContext.refs;
        const config = reactive(_.cloneDeep(tableConfig));
        const advanceDialogRef = ref(null);
        const isDisableBtn = ref(false);
        const saveLoading = ref(false);
        class FilesDetailClass extends AdvanceListClass {
            beforeGetList (condition) {
                condition.customerCode = props.detailData.customerCode;
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const subTabTable = new FilesDetailClass({
            Api: {
                listApi: getEbcaInfoList
            }
        });
        const newColumns = computed(() => {
            return config.columns;
        });
        const btnLoading = ref(false);
        const handlePush = () => {
            if (!selections.value.length) {
                return Message.warning('请选择一条数据进行操作');
            };
            const params = selections.value.map(it => it.id);
            pushEbcaStatus(params).then(res => {
                if (res && +res.code === 0) {
                    Message.success('推送成功');
                }
            }).finally(() => {
                subTabTable.getList();
            });
        };
        const selections = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selections.value = selection;
        };
        const handleUseStatus = () => {
            advanceDialogRef.value.show(true, {
                label: '会计主体',
                prop: 'accountEntityName',
                value: 'accountEntityName',
                mustFill: true,
                type: 'advance',
                width: 200,
                // advanceConfig配置搜索条件，tableConfig配置弹窗中的表格列信息
                advanceConfig: [
                    { prop: 'operatingUnitName', value: '会计主体名称' },
                    { prop: 'operatingUnitId', value: '会计主体编码' }
                ],
                tableConfig: [
                    { prop: 'operatingUnitId', value: '会计主体编码' },
                    { prop: 'operatingUnitName', value: '会计主体名称' }
                ],
                prefix: '/api-lcrm',
                // method: 'post',
                advanceUrl: `/efOrganRelations`,
                cbParams: ['operatingUnitId#accountEntityId', 'operatingUnitName#accountEntityName', 'legalEntityName'], // legalSysId: 会计主体id
                beforeRequest: (params) => {
                    if (params.operatingUnitName) {
                        return params;
                    }
                    const inputKey = params.operatingUnitId?.trim();
                    const reg = new RegExp(/^-?\d+$/); // 数字正则
                    if (!reg.test(inputKey)) {
                        params.operatingUnitName = inputKey;
                        params.operatingUnitId = null;
                    } else {
                        params.operatingUnitId = inputKey;
                        params.operatingUnitName = null;
                    }
                    return params;
                },
                afterResponse: (res) => {
                    if (res && res.data && Array.isArray(res.data.list)) {
                        res.data.list = res.data.list.filter(item => !_.isNil(item));
                    }
                    return _.cloneDeep(res);
                }
            // sort: true
            // advanceCode: 'advanceCode'
            });
        };
        const handleDelStatus = () => {
            if (!selections.value.length) {
                return Message.warning('请选择一条数据进行操作');
            };
            if (selections.value.find(it => +it.approveStatus !== 0)) {
                return Message.warning('请选择未审批的数据');
            }
            MessageBox.confirm(`确认删除当前选择的数据？`, `提示：`, {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const params = selections.value.map(it => it.id);
                delEbcaStatus(params).then(res => {
                    if (res && +res.code === 0) {
                        Message.success('删除成功');
                    }
                }).finally(() => {
                    subTabTable.getList();
                });
            }).catch(() => { subTabTable.getList(); });
        };
        watch(
            () => [props.activeName],
            // eslint-disable-next-line complexity
            (newVal) => {
                if (newVal[0] === 'ebca') {
                    if (newVal[0]) {
                        subTabTable.getList();
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        const detaliSearchSure = (data) => {
            const params = {
                customerCode: props.detailData.customerCode,
                customerAccount: data.accountEntityId
            };
            saveEbcaInfo(params).then(res => {
                if (res && +res.code === 0) {
                    Message.success('新增成功');
                }
            }).finally(() => {
                subTabTable.getList();
            });
        };
        const handleConfirmStatus = () => {
            if (!selections.value.length) {
                return Message.warning('请选择一条数据进行操作');
            };
            if (selections.value.find(it => +it.approveStatus !== 0)) {
                return Message.warning('请选择未审批的数据');
            }

            const params = selections.value.map(it => it.id);
            confirmEbcaStatus(params).then(res => {
                if (res && +res.code === 0) {
                    Message.success('确认成功');
                }
            }).finally(() => {
                subTabTable.getList();
            });
        };
        const handleResize = () => {
            setupContext.root.$nextTick(() => {
                setupContext.refs.editTableBox.$refs.moduleTable.debouncedUpdateLayout();
            });
        };
        onMounted(() => {
            window.addEventListener('resize', handleResize);
        });
        onUnmounted(() => {
            window.removeEventListener('resize', handleResize);
        });
        return {
            config,
            subTabTable,
            advanceDialogRef,
            btnLoading,
            handlePush,
            handleSelectedRow,
            isDisableBtn,
            saveLoading,
            authorityCode,
            newColumns,
            handleDelStatus,
            handleUseStatus,
            detaliSearchSure,
            handleConfirmStatus
        };
    }
};
</script>

<style lang = "less">
.customer-subtab {
    .file-detail-btn {
        width: 100%;
        display: flex;
        align-items: center;
        .sub-tab-search-box {
            flex: 1;
            height: 28px;
            margin: 0 0 0 30px;
            .search-box {
                &>div>button.search-btn {
                    height: 24px;
                    min-height: 24px;
                    padding: 2px 4px;
                    margin-top: 2px;
                    /* background-color: #4285F5;
                    border-color: #4285F5; */
                }
                .search {
                    .el-input-group__append, .el-input-group__prepend {
                        padding: 0 14px;
                    }
                }
            }
        }
    }
}
.el-table--border th.gutter:last-of-type { display: block!important; width: 17px!important; }
</style>
