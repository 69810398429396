import store from '@/store/index';
const otherUtils = {
    /**
     *
     * @param {string} value 权限平台的按钮权限值
     * @return 权限检查方法
     */
    getHasAuth (value) {
        let isExist = false;
        const btnPermissionsArr = store.getters.btnPermissions;
        if (btnPermissionsArr === undefined || btnPermissionsArr === null) {
            return false;
        }
        if (btnPermissionsArr.indexOf(value) > -1) {
            isExist = true;
        } else {
            isExist = false;
        }
        return isExist;
    },
    /**
    *
    * @param {array} arr 需要去重的数组
    * @param {string} key 判断数组重复的唯一key
    * @return 去重
    */
    distinctData (arr, key) {
        // 数组去重
        const obj = {};
        arr = arr
            .filter(i => !!i[key])
            .reduce(function (item, next) {
                if (!obj[next[key]]) {
                    obj[next[key]] = item.push(next);
                }
                return item;
            }, []);
        return arr;
    }
};
export default otherUtils;
