<!-- eslint-disable max-lines -->
<template>
    <div class="file-detail-subtab customer-subtab">
        <div class="file-detail-btn" >
            <lots-button
                type="text"
                icon="el-icon-circle-check"
                size="medium"
                class="bigData-btn-detailPageStamperTabUse"
                v-has="authorityCode.subStamperUse"
                @click="handleUseStatus"
                >启用</lots-button>
            <lots-button
                type="text"
                icon="el-icon-remove-outline"
                size="medium"
                class="bigData-btn-detailPageStamperTabStop"
                :loading="saveLoading"
                v-has="authorityCode.subStamperStop"
                @click="handleStopStatus">停用</lots-button>
            <lots-button
                 type="text" icon="el-icon-refresh" size="medium" v-has="authorityCode.subStamperSync"
                @click="handleSync()"  class="bigData-btn-detailPageStamperTabSync">同步</lots-button>
            <lots-button @click="openLink" v-has="authorityCode.subStamperLink"  class="bigData-btn-detailPageStamperTabLink" type="text" size="medium" icon="el-icon-link" >
                前往授权
            </lots-button>
        </div>
        <div class="file-detail-table">
            <edit-table-box
                class="prescreen"
                v-loading="subTabTable.tableLoading"
                ref="editTableBox"
                :selection="config.selection"
                :control="false"
                :columns="newColumns"
                :height="tableHeight"
                :tableData="subTabTable.totalData"
                :actions="config.actions"
                :editDisableRule="() => false"
                :allowRowClick="false"
                @selection-change="handleSelectedRow"
                >
            </edit-table-box>
            <div class="footer">
                <lots-pagination
                @size-change="subTabTable.sizeChange"
                :current-page.sync="subTabTable.pageNo"
                @current-change="subTabTable.pageChange"
                size="small"
                layout="total, sizes, prev, pager, next, jumper"
                :total="subTabTable.pageTotal" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, watch } from '@vue/composition-api';
import editTableBox from '@/components/lots/editTableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import lotsButton from '@/components/lots/lotsButton';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import {
    updateStamperStatus,
    syncStamperStatus,
    getStamperInfoList
} from '@mdm/api/customer/customer.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { tableConfig } from './config.js';
import mConfig from '@/config/user.env';
import { Message, MessageBox } from 'element-ui';
import { STATE_OTHER_CHANGE } from '@/modules/mdm/constant/contractManage.js';
import _ from 'lodash';
export default {
    name: '',
    components: {
        lotsPagination,
        lotsButton,
        editTableBox
    },
    props: {
        activeName: {
            type: String,
            default: () => ''
        },
        detailData: {
            type: Object,
            return: () => {}
        },
        tableHeight: {
            type: Number,
            default: 325
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const authorityCode = reactive(btnAuthority.customer);
        // const refs = setupContext.refs;
        const config = reactive(_.cloneDeep(tableConfig));
        const isDisableBtn = ref(false);
        const saveLoading = ref(false);
        class FilesDetailClass extends AdvanceListClass {
            beforeGetList (condition) {
                condition.customerCode = props.detailData.customerCode;
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0) {
                    res.data.list.forEach(item => {
                        item.edit = -3;
                    });
                    this.totalData.value = res.data.list;
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const subTabTable = new FilesDetailClass({
            Api: {
                listApi: getStamperInfoList
            }
        });
        const newColumns = computed(() => {
            return config.columns;
        });
        const btnLoading = ref(false);
        const handleAddRow = () => {
            // refs.editTableBox.addRow();
            const newItem = {
                edit: 2,
                sealAdminName: undefined
            };
            subTabTable.totalData.value.unshift(newItem);
        };
        const handleSync = () => {
            syncStamperStatus({
                customerCode: props.detailData.customerCode,
                customerName: props.detailData.customerName,
                societyCreditCode: props.detailData.societyCreditCode
            }).then(res => {
                if (res && +res.code === 0) {
                    Message.success('同步成功');
                }
            }).finally(() => {
                subTabTable.getList();
            });
        };
        const selections = ref([]); // 选中的行数组
        const handleSelectedRow = (selection) => {
            selections.value = selection;
        };
        const sealAdminKey = ref(false);
        const openLink = () => {
            window.open(mConfig.mSign, '_blank');
        };
        const handleUseStatus = () => {
            if (selections.value.length !== 1) {
                return Message.warning('请选择一条数据进行操作');
            };
            updateStamperStatus({
                customerCode: props.detailData.customerCode,
                id: selections.value[0].id,
                enableFlag: '1'
            }).then(res => {
                if (res && +res.code === 0) {
                    Message.success('启用成功');
                }
            }).finally(() => {
                subTabTable.getList();
            });
        };
        const handleStopStatus = () => {
            if (selections.value.length !== 1) {
                return Message.warning('请选择一条数据进行操作');
            };
            MessageBox.confirm(`确认停用当前选择的数据？`, `提示：`, {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                updateStamperStatus({
                    customerCode: props.detailData.customerCode,
                    id: selections.value[0].id,
                    enableFlag: '0'
                }).then(res => {
                    if (res && +res.code === 0) {
                        Message.success('停用成功');
                    }
                }).finally(() => {
                    subTabTable.getList();
                });
            }).catch(() => { subTabTable.getList(); });
        };
        watch(
            () => [props.activeName],
            // eslint-disable-next-line complexity
            (newVal) => {
                if (newVal[0] === 'stamper') {
                    if (newVal[0]) {
                        subTabTable.getList();
                    } else {
                        subTabTable.totalData.value = [];
                        subTabTable.pageTotal.value = 0;
                    }
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        return {
            config,
            subTabTable,
            btnLoading,
            handleAddRow,
            handleSync,
            handleSelectedRow,
            isDisableBtn,
            saveLoading,
            authorityCode,
            STATE_OTHER_CHANGE,
            newColumns,
            sealAdminKey,
            openLink,
            handleStopStatus,
            handleUseStatus
        };
    }
};
</script>

<style lang = "less">
.customer-subtab {
    .file-detail-btn {
        width: 100%;
        display: flex;
        align-items: center;
        .sub-tab-search-box {
            flex: 1;
            height: 28px;
            margin: 0 0 0 30px;
            .search-box {
                &>div>button.search-btn {
                    height: 24px;
                    min-height: 24px;
                    padding: 2px 4px;
                    margin-top: 2px;
                    /* background-color: #4285F5;
                    border-color: #4285F5; */
                }
                .search {
                    .el-input-group__append, .el-input-group__prepend {
                        padding: 0 14px;
                    }
                }
            }
        }
    }
}

</style>
