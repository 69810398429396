<!-- eslint-disable max-lines -->
<template>
    <div class="contract-detail contract-view"
        v-loading="detailLoading">
        <div>
            <div class="customer-head">
                <div>{{ detailDataLocal.customerName }}</div>
                 <div v-if="dictData[detailDataLocal.customerStatus]" :class="['customer-status','customer-' + detailDataLocal.customerStatus]">
                    {{ dictData[detailDataLocal.customerStatus] }} </div>
                </div>
        </div>
        <!-- 按钮区域 -->
        <div class="detail-toolBar">
            <el-tooltip
                class="item-flow-right"
                effect="dark"
                content="刷新"
                placement="bottom">
                <lots-button
                size="mini"
                icon="el-icon-refresh"
                v-show="code !== '0000'"
                @click="refleshPage"
                :loading="detailLoading"></lots-button>
            </el-tooltip>
        </div>
        <!-- 表单信息组件 -->
        <collapse-infos
            ref="upholdHealder"
            :contractCode="detailDataLocal.contractCode"
            :detailData="detailDataLocal"
            :activeCollapses="['manageInfo', 'customerInfo', 'basicInfo', 'creditInfo']"
            :collapse="COLLAPSE"
            :detailPageState="detailPageState"
            @change="handleInfosChange">
        </collapse-infos>
        <!-- 子页签组件 -->
        <el-collapse
            class="contract-collapse detail-more-collapse"
            value="moreInfo"
            @change="handleClickMoreInfo">
            <el-collapse-item title="更多信息" name="moreInfo" ref="tabs">
                <el-tabs v-model="activeName" @tab-click="handleClickTab">
                    <el-tab-pane label="盖章人" name="stamper" v-if="subTabShow.coustomer && detailDataLocal.isVirtualCustomer === 'N'">
                        <customer-detail
                            ref="stamperRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal"
                            :readonly="true">
                        </customer-detail>
                    </el-tab-pane>
                    <el-tab-pane label="业务实体" name="ebca" v-if="subTabShow.subEcba">
                        <Ebca-detail
                            ref="ebcaRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal"
                            :readonly="true">
                        </Ebca-detail>
                    </el-tab-pane>
                </el-tabs>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import { collapse } from './collapseInfosConfig.js';
import CustomerDetail from '@/modules/mdm/views/customerModules/customer/detail/stamper/Index.vue';
import EbcaDetail from '@/modules/mdm/views/customerModules/customer/detail/ebca/Index.vue';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { getCustomerDetailInfo } from '@mdm/api/customer/customer.js';
import _ from 'lodash';
import { getDictDataApi } from '@mdm/api/contractManage/contractListApi.js';
import otherUtils from '@/modules/mdm/utils/otherCommonFunc.js';
export default {
    name: '',
    components: {
        lotsButton,
        CollapseInfos,
        CustomerDetail,
        EbcaDetail
    },
    props: {
        code: { // 页面识别码（可能是contractCode 或者 contractChangeCode: 当页面是其他变更并且有变更编号时）
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            // 是否跳转变更页传递了个页面状态过来
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            detailLoading: false,
            activeName: 'stamper',
            collapse: _.cloneDeep(collapse),
            detailDataLocal: _.cloneDeep(this.detailData),
            authorityCode: btnAuthority.contractManage,
            dictData: {}
        };
    },
    watch: {
        detailData: {
            handler (val) {
                this.refleshPage();
            },
            deep: true
        }
    },
    computed: {
        // eslint-disable-next-line complexity
        subTabShow() {
            return {
                coustomer: otherUtils.getHasAuth(
                    btnAuthority.customer.subStamper
                ), // 新增：不展示
                subEcba: otherUtils.getHasAuth(
                    btnAuthority.customer.subEcba
                )
            };
        },
        // eslint-disable-next-line
        COLLAPSE () { // 配置项动态计算
            return this.collapse;
        }
    },
    async mounted () {
        const dictCodes = ['SYS_WM_CUSTOMER_STATUS'];
        const res = await getDictDataApi({ dictCodes: String(dictCodes) });
        this.dictData = res.data.SYS_WM_CUSTOMER_STATUS;
        this.refleshPage();
    },
    methods: {
        handleInfosChange (formObjData = {}) { // 表单组件编辑回调，更新本地数据
            this.detailDataLocal = _.cloneDeep(formObjData.form);
            // if (this.detailDataLocal?.isVirtualCustomer === 'Y') { // 虚拟客户collapse修改
            //     this.collapse.splice(0, 1, virtualCustomerInfo);
            // }
            const { itemSetting } = formObjData;
            if (!itemSetting) {
                this.$refs.upholdHealder.refleshPage(this.detailDataLocal); // 刷新表单子组件
            }
            if (!(this.subTabShow.coustomer && this.detailDataLocal.isVirtualCustomer === 'N')) {
                this.activeName = 'ebca';
            }
        },
        async refleshPage() {
            this.detailLoading = true;
            getCustomerDetailInfo({ ebcuId: this.detailData.ebcuId }).then(res => {
                if (res.code === '0') {
                    this.dataInit(res.data);
                }
            }).finally(() => {
                this.detailLoading = false;
            });
        },
        dataInit (data) {
            this.handleInfosChange({ form: data || {} });
        },
        handleClickTab (data) {
            const editTabArr = ['stamper'];
            if (editTabArr.includes(data.name)) { // 解决切换编辑表格时操作列错位问题
                this.$nextTick(() => {
                    const tableRef = this.$refs[`${data.name}Ref`]?.$refs.editTableBox;
                    if (tableRef) {
                        tableRef.$refs.moduleTable.doLayout();
                    }
                });
            }
        },
        handleClickMoreInfo(data) { // 解决点击折叠/展开“更多信息”折叠筐，子页签编辑错位问题
            if (data.includes('moreInfo')) {
                this.handleClickTab({ name: this.activeName });
            }
        }
    }
};
</script>

<style lang = "less">
.contract-detail {
    .detail-toolBar {
        .contract-detail-btn {
            margin-right: 10px;
        }
        .item-flow-right {
            float: right;
            margin-left: 10px;
        }
  }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }
    .el-tabs__header {
        margin-bottom: 0px;
    }
    .el-tabs__content {
        .file-detail-btn {
            background-color: #fffbf2;
            .el-button {
                padding: 8px 0px;
            }
        }
        .footer {
            padding: 10px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .detail-more-collapse.el-collapse {
        border-top: none;
        border-bottom: none;
    }
    .detail-toolBar {
        .el-loading-spinner {
            top: 0;
            margin-top: 0;
            height: 100%;
            .circular {
                height: 26px;
                width: 26px;
            }
        }
    }
    &.contract-view {
        .el-col.el-col-6.form-item {
            .el-form-item.el-form-item--mini {
                margin-bottom: 2px;
            }
        }
        .el-col.el-col-24.form-item {
            .el-form-item.el-form-item--mini {
                margin-bottom: 2px;
            }
        }
    }
}
.customer-head{
    font-size: 18px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    .customer-status{
        font-size: 12px;
        padding: 4px 8px;
        color: #ffffff;
        border-radius: 10px;
        margin-left: 8px;
    }
    .customer-0{
        background: #f2761f;
    }
    .customer-1{
        background: #6AE7B0;
    }
    .customer-2{
        background: #999999;
    }
    .customer-3{
        background: #f44040;
    }
    .customer-4{
        background: #4285f5;
    }
}
</style>
