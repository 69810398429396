export const config = {
    name: 'costomer',
    searchFields: [
        {
            name: '客户名称',
            value: 'customerName',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户编码',
            value: 'customerCode',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '客户状态',
            value: 'customerStatuss',
            type: 'select',
            optionNum: 'SYS_WM_CUSTOMER_STATUS',
            multiple: true,
            isFixed: true,
            span: 3
        },
        {
            name: '是否虚拟客户',
            value: 'isVirtualCustomer',
            type: 'select',
            optionNum: 'SYS_CRM_YES_NO',
            isFixed: true,
            span: 3
        },
        {
            name: '创建时间',
            value: 'createTime',
            isFixed: true,
            type: 'time',
            time: 'daterange',
            startPlaceholder: '创建时间从',
            endPlaceholder: '创建时间到',
            span: 5
        },
        {
            name: '客户简称',
            value: 'customerShortName',
            type: 'input',
            span: 3
        },
        {
            name: '客户集团编码',
            value: 'originalGroupCode',
            type: 'input',
            span: 3
        },
        {
            name: '行业类型',
            value: 'industryType',
            type: 'select',
            optionNum: 'SYS_INDUSTRY_TYPE',
            span: 3
        },
        {
            name: '内外渠',
            value: 'customerChannels',
            type: 'select',
            optionNum: 'LMDM_CUSTOMER_CHANNEL',
            multiple: true,
            span: 3
        },
        {
            name: '渠道角色',
            value: 'customerChannelRoles',
            type: 'select',
            optionNum: 'LMDM_CUSTOMER_CHANNEL_ROLE',
            multiple: true,
            span: 3
        },
        {
            name: '客户等级',
            value: 'customerLevelCode',
            type: 'select',
            optionNum: 'SYS_WM_CUSTOMER_LEVEL',
            span: 3
        },
        {
            name: '客户风险等级',
            value: 'riskLevel',
            type: 'select',
            optionNum: 'LMDM_RISKLEVEL',
            span: 3
        },
        {
            name: '客户控制类型',
            value: 'customerBodyTypes',
            type: 'select',
            optionNum: 'SYS_RELATION_LEVEL',
            multiple: true,
            span: 3
        },
        {
            name: '社会信用代码',
            value: 'societyCreditCode',
            type: 'input',
            span: 3
        },
        {
            name: '身份证',
            value: 'idNumber',
            type: 'input',
            span: 3
        },
        {
            name: '创建人',
            value: 'createUserCodes',
            type: 'advanceUser',
            isMultCheck: true,
            span: 3
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '客户名称',
                prop: 'customerName',
                minWidth: 200
            },
            {
                label: '客户编码',
                prop: 'customerCode',
                minWidth: 120
            },
            {
                label: '社会信用代码',
                prop: 'societyCreditCode',
                minWidth: 160
            },
            {
                label: '身份证',
                prop: 'idNumber',
                minWidth: 150
            },
            {
                label: '客户状态',
                type: 'select',
                optionsKey: 'SYS_WM_CUSTOMER_STATUS',
                prop: 'customerStatus',
                minWidth: 120
            },
            {
                label: '内外渠',
                type: 'select',
                optionsKey: 'LMDM_CUSTOMER_CHANNEL',
                prop: 'customerChannel',
                minWidth: 120
            },
            {
                label: '渠道角色',
                prop: 'customerChannelRole',
                type: 'select',
                optionsKey: 'LMDM_CUSTOMER_CHANNEL_ROLE',
                minWidth: 120
            },
            {
                label: '客户系',
                prop: 'customerGroupCode',
                type: 'select',
                optionsKey: 'SYS_CUST_RELATION_TYPE',
                minWidth: 120
            },
            {
                label: '客户等级',
                prop: 'customerLevelCode',
                type: 'select',
                optionsKey: 'SYS_WM_CUSTOMER_LEVEL',
                minWidth: 120
            },
            {
                label: '行业类型',
                prop: 'industryType',
                type: 'select',
                optionsKey: 'SYS_INDUSTRY_TYPE',
                minWidth: 150
            },
            {
                label: '客户简称',
                prop: 'customerShortName',
                minWidth: 150
            },
            {
                label: '客户集团编码',
                prop: 'originalGroupCode',
                minWidth: 150
            },
            {
                label: '客户销售易id',
                prop: 'crmCustomerId',
                minWidth: 150
            },
            {
                label: '是否虚拟客户',
                type: 'select',
                optionsKey: 'SYS_CRM_YES_NO',
                prop: 'isVirtualCustomer',
                minWidth: 120
            },
            {
                label: '客户控制类型',
                type: 'select',
                optionsKey: 'SYS_RELATION_LEVEL',
                prop: 'customerBodyType',
                minWidth: 150
            },
            {
                label: '客户风险等级',
                prop: 'riskLevel',
                type: 'select',
                optionsKey: 'LMDM_RISKLEVEL',
                minWidth: 120
            },
            {
                label: '创建人mip',
                prop: 'createUserCode',
                minWidth: 150
            },
            {
                label: '创建人名称',
                prop: 'createUserName',
                minWidth: 120
            },
            {
                label: '创建时间',
                prop: 'createTime',
                minWidth: 150
            },
            {
                label: '修改人mip',
                prop: 'updateUserCode',
                minWidth: 150
            },
            {
                label: '修改人名称',
                prop: 'updateUserName',
                minWidth: 120
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                minWidth: 150
            },
            {
                label: '客户开发人美信号',
                prop: 'customerOwnerCode',
                minWidth: 150
            },
            {
                label: '客户开发人名称',
                prop: 'customerOwnerName',
                minWidth: 120
            }
        ]
    },
    PANELMAX: 8,
    LASTFOUR: 4,
    LASTSIX: 6
};
