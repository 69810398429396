import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 客户列表
export const getCustomerInfoPage = (data) => {
    return request({
        url: apiCrmHost + '/customer/info/page/auth',
        method: 'post',
        data
    });
};
// 客户详情-zxh
export const getCustomerDetailInfo = (params) => {
    return request({
        url: apiCrmHost + '/customer/info/getById',
        method: 'get',
        params
    });
};
// 客户-签章人列表
export const getStamperInfoList = (data) => {
    return request({
        url: apiCrmHost + '/customer/stamper/info/page',
        method: 'post',
        data
    });
};
// 客户详情-签章人-启用-zxh
export const updateStamperStatus = (data) => {
    return request({
        url: apiCrmHost + '/customer/stamper/info/enableFlag',
        method: 'post',
        data
    });
};
// 客户详情-签章人-同步-rzq
export const syncStamperStatus = (data) => {
    return request({
        url: apiCrmHost + '/customer/stamper/sync/info',
        method: 'post',
        data
    });
};
// 客户-业务实体-新增
export const saveEbcaInfo = (data) => {
    return request({
        url: apiCrmHost + '/customer/account/save',
        method: 'post',
        data
    });
};
// 客户-业务实体
export const getEbcaInfoList = (data) => {
    return request({
        url: apiCrmHost + '/customer/account/query',
        method: 'post',
        data
    });
};
// 客户详情-删除-rzq
export const delEbcaStatus = (data) => {
    return request({
        url: apiCrmHost + '/customer/account/del',
        method: 'post',
        data
    });
};
// 客户详情-删除-rzq
export const pushEbcaStatus = (data) => {
    return request({
        url: apiCrmHost + '/customer/account/push/erp',
        method: 'post',
        data
    });
};
// 客户详情-确认-rzq
export const confirmEbcaStatus = (data) => {
    return request({
        url: apiCrmHost + '/customer/account/confirm',
        method: 'post',
        data
    });
};
