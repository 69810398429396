export const tableConfig = {
    selection: true,
    height: 325,
    columns: [
        {
            label: '业务实体编码',
            prop: 'customerAccount',
            sort: true,
            width: 114
        },
        {
            label: '业务实体名称',
            prop: 'customerAccountName',
            sort: true,
            width: 114
        },
        {
            label: '审批状态',
            prop: 'approveStatus',
            type: 'select',
            optionsKey: 'CRM_ACCOUNT_APPROVE_STATUS',
            sort: true,
            width: 80
        },
        {
            label: '推送ERP状态',
            prop: 'isErpSynchronize',
            type: 'select',
            optionsKey: 'LCRM_ERPSYNCHRONIZE_STATUS',
            sort: true,
            width: 114
        },
        {
            label: '推送ERP时间',
            prop: 'pushErpTime',
            sort: true,
            width: 114
        },
        {
            label: '推送失败原因',
            prop: 'errorMsg',
            sort: true,
            width: 114
        },
        {
            label: '创建人',
            sort: true,
            prop: 'createUserCode',
            width: 120
        },
        {
            label: '创建时间',
            prop: 'createTime',
            sort: true,
            width: 120
        },
        {
            label: '修改人',
            sort: true,
            prop: 'updateUserCode',
            width: 114
        },
        {
            label: '修改时间',
            prop: 'updateTime',
            sort: true,
            width: 114
        }
    ]
};
